/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * GlobalSearchConfig
 * @export
 * @class GlobalSearchConfig
 */
export class GlobalSearchConfig {
  public static readonly minSearchLength = 2;
}
